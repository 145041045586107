import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { CustomloginRequest } from "../authConfig";
import { IGraphDataUserProfile } from "../interfaces/IGraphData";
import APIService from "../services/api.service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiService = new APIService();

export const Form: React.FC<{ user: IGraphDataUserProfile }> = ({ user }) => {
  const [certificado, setCertificado] = useState("");
  const [curso, setCurso] = useState("");
  const [email] = useState(user.userPrincipalName);
  const [workload, setWorkload] = useState("");
  const [tipo, setTipo] = useState("Pesquisa");
  const [date, setDate] = useState("");
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const { instance } = useMsal();

  type Document = string;

  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = (await convertBase64(file)) as Document;
    const [, type] = base64.split(";")[0].split("/");

    setDocument(base64);
    setLoaded(true);
    setDocumentName(e.target.files[0].name);
    if (!type.includes("pdf")) {
      toast.error("O arquivo deve ser um PDF", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = () => {
    if (!certificado || !curso || !workload || !date || !document) {
      toast.error("Preencha todos os campos", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    async function sendActivity() {
      setLoading(true);
      const token = (await instance.acquireTokenSilent({
        ...CustomloginRequest,
        account: instance.getActiveAccount() as AccountInfo,
      })) as AuthenticationResult;
      const data = JSON.stringify({
        certificate_name: certificado,
        course_name: curso,
        email: email,
        workload: workload,
        type_activity: tipo,
        date_of_issue: date,
        document: document,
      });
      const accessToken = token.accessToken;
      apiService
        .sendActivity({ data, accessToken })
        .then(() => {
          setLoading(false);
          toast.success("Certificado enviado com sucesso!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch(() => {
          setLoading(false);
          toast.error("Erro ao enviar o certificado", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    sendActivity();
  };

  return (
    <>
      <div className="justify-content">
        <div className="max-w-[75%] w-full mx-auto">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />
          <div className="text-center">
            <h1 className="text-3xl font-bold">
              <span className="text-black">
                Envio de atividades complementares
              </span>
            </h1>
            <h2 className="text-xl font-bold">
              <span className="text-blue-500">Bem-vindo</span>{" "}
              {user.displayName}
            </h2>
          </div>
          <div className="shadow-xl overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="certificate-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nome do Certificado
                  </label>
                  <input
                    type="text"
                    name="certificate-name"
                    onChange={(e) => setCertificado(e.target.value)}
                    id="certificate-name"
                    autoComplete="certificate-name"
                    className="mt-1 focus:ring-blue-600 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="course"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Curso
                  </label>
                  <input
                    type="text"
                    name="course"
                    onChange={(e) => setCurso(e.target.value)}
                    id="course"
                    autoComplete="given-name"
                    className="mt-1 focus:ring-blue-600 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <label
                    htmlFor="email-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-mail
                  </label>
                  <input
                    type="text"
                    name="email-address"
                    id="email-address"
                    value={user.userPrincipalName}
                    disabled
                    autoComplete="email-address"
                    className="cursor-not-allowed opacity-50 mt-1 focus:ring-blue-600 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6 sm:col-span-1">
                  <label
                    htmlFor="workload"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Carga horária
                  </label>
                  <input
                    type="number"
                    name="workload"
                    onChange={(e) => setWorkload(e.target.value)}
                    id="workload"
                    autoComplete="workload"
                    className="mt-1 focus:ring-blue-600 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-1">
                  <label
                    htmlFor="certificate-type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Tipo de atividade
                  </label>
                  <select
                    id="certificate-certificate-type"
                    name="certificate-type"
                    autoComplete="certificate-type"
                    onChange={(e) => setTipo(e.target.value)}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-600 focus:border-blue-900 sm:text-sm"
                  >
                    <option>Pesquisa</option>
                    <option>Extenção</option>
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                  <label
                    htmlFor="date-of-issue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Data da emissão
                  </label>
                  <input
                    type="date"
                    name="date-of-issue"
                    onChange={(e) => setDate(e.target.value)}
                    id="date-select"
                    className="mt-1 focus:ring-blue-600 focus:border-blue-900 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <div className="mt-6 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  {loaded ? (
                    <div className="space-y-1 text-center">{documentName}</div>
                  ) : (
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer bg-white rounded-md font-medium text-blue-900 hover:text-blue-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-800"
                        >
                          <span>Carregar certificado</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            accept="application/pdf"
                            onChange={(e) => {
                              uploadImage(e);
                            }}
                            type="file"
                            className="sr-only"
                          />
                        </label>
                        <p className="pl-1">ou arrastar e soltar</p>
                      </div>
                      <p className="text-xs text-gray-500">PDF até 10MB</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                onClick={handleSubmit}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
              >
                {loading === false ? (
                  "Enviar"
                ) : (
                  <>
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span>Enviando...</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
