// MSAL Imports
import { graphConfig } from "../authConfig";
import { callMsGraph } from "../utils/MsGraph";

// Interfaces
import { IMembers } from "../interfaces/IMembers";
import { IUser } from "../interfaces/IUser";
import { INextLink } from "../interfaces/INextLink";

class GraphService {
  public getGroup = async () => {
    return fetch(graphConfig.graphMemberOfMeEndpoint, await callMsGraph())
      .then((response) => response.json())
      .catch((error) => console.log(error));
  };
  public getMe = async () => {
    return fetch(graphConfig.graphMeEndpoint, await callMsGraph())
      .then((response) => response.json())
      .catch((error) => console.log(error));
  };

  public getMembers = async ({ id }: IMembers) => {
    return fetch(
      graphConfig.graphGetMembersEndpoint.replace("{group-id}", id),
      await callMsGraph()
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  };

  public getUser = async ({ email }: IUser) => {
    return fetch(
      graphConfig.graphGetUserEndpoint.replace("{email-address}", email),
      await callMsGraph()
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  };

  public getNextLink = async ({ nextLink }: INextLink) => {
    return fetch(nextLink, await callMsGraph())
      .then((response) => response.json())
      .catch((error) => console.log(error));
  };
}

export default GraphService;
