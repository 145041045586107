import { useState } from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { IGraphDataUserProfile } from "../interfaces/IGraphData";
import { SignOutButton } from "./SignOutButton";

export const Header: React.FC<{ user: IGraphDataUserProfile }> = ({ user }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const logo = require("../assets/unifacef_logo_blue.png");

  return (
    <nav className="bg-white border-gray-200 px-2 md:px-4 py-2.5 white:bg-white-900">
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl mx-auto">
        <a href="/" className="flex items-center">
          <img className="h-12 w-auto" src={logo} alt="UniFACEF" />
        </a>
        <div className="hidden md:block">
          <div className="ml-4 flex items-center md:ml-6">
            <SignOutButton />
            <div className="ml-3 relative">
              <div>
                <button
                  className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                >
                  <Avatar name={user.displayName} size={"40"} round={true} />
                </button>
              </div>

              {showProfileMenu && (
                <div className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1 rounded-md bg-white shadow-xs"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <div className="ml-3 mr-3 mb-2">
                      <div className="text-base font-medium leading-none text-black">
                        {user.displayName}
                      </div>
                      <div className="text-smyy leading-none text-gray-600">
                        {user.userPrincipalName}
                      </div>
                    </div>
                    <div className="hidden sm:block" aria-hidden="true">
                      <div className="border-t border-gray-200" />
                    </div>
                    <Link
                      to={`/profile`}
                      className="block px-3 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-200"
                    >
                      Meu perfil
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="-mr-2 flex md:hidden">
          <div className="flex items-center mr-4">
            <SignOutButton />
          </div>
          <button
            onClick={() => setShowMenu(!showMenu)}
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-800 focus:outline-none focus:bg-blue-800 focus:text-white"
          >
            <svg
              className="block h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>

            <svg
              className="hidden h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className={`md:hidden ${showMenu ? "block" : "hidden"}`}>
        <div className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1 rounded-md bg-white shadow-xs"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                <Avatar name={user.displayName} size={"40"} round={true} />
              </div>
              <div className="ml-3 mr-3 mb-2">
                <div className="text-base font-medium leading-none text-black">
                  {user.displayName}
                </div>
                <div className="text-sm leading-none text-gray-600">
                  {user.userPrincipalName}
                </div>
              </div>
              <div className="hidden sm:block" aria-hidden="true">
                <div className="border-t border-gray-200" />
              </div>
              <Link
                to={`/profile`}
                className="block px-3 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-200"
              >
                Meu perfil
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
