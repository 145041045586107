import { useState } from "react";

export const LoadingProfile = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const logo = require("../assets/unifacef_logo_blue.png");

  return (
    <>
      <nav className="bg-white border-gray-200 px-2 md:px-4 py-2.5 white:bg-white-900">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl mx-auto animate-pulse">
          <a href="https://unifacef.com.br" className="flex items-center">
            <img className="h-12 w-auto" src={logo} alt="UniFACEF" />
          </a>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <div className="w-20 h-8 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm rounded-md bg-gray-300"></div>
              <div className="ml-3 relative">
                <div>
                  <button
                    className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    onClick={() => setShowProfileMenu(!showProfileMenu)}
                  >
                    <div className="bg-cover h-8 w-8 rounded-full bg-gray-300"></div>
                  </button>
                </div>

                {showProfileMenu && (
                  <div className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                    <div
                      className="py-1 rounded-md bg-white shadow-xs"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <div className="ml-3 mr-3">
                        <div className="w-36 bg-gray-300 h-6 rounded-md mb-1"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <div className="flex items-center mr-4">
              <div className="w-20 bg-gray-300 h-6 rounded-md mb-1"></div>
            </div>
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-800 focus:outline-none focus:bg-blue-800 focus:text-white"
            >
              <svg
                className="block h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className={`md:hidden ${showMenu ? "block" : "hidden"}`}>
          <div className="origin-top-right absolute right-0 mt-2 w-50 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
            <div
              className="py-1 rounded-md bg-white shadow-xs"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <div className="bg-cover h-8 w-8 rounded-full bg-gray-300"></div>
                </div>
                <div className="ml-3 mr-3 mb-2">
                  <div className="w-36 bg-gray-300 h-6 rounded-md mb-1"></div>
                </div>
                <div className="hidden sm:block" aria-hidden="true">
                  <div className="border-t border-gray-200" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="font-sans leading-tight bg-grey-lighter animate-pulse">
        <div className="max-w-lg mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
          <div className="bg-cover h-40 bg-gray-300"></div>
          <div className="border-b px-4 pb-6">
            <div className="text-center sm:text-left sm:flex mb-4">
              <img
                className="h-32 w-32 rounded-full border-4 border-white -mt-16 mr-4 bg-gray-300"
                alt=""
              />
              <div className="py-2">
                <div className="w-36 bg-gray-300 h-6 rounded-md mb-1"></div>
                <div className="w-24 bg-gray-300 h-6 rounded-md mb-1"></div>
              </div>
            </div>
          </div>
          <div className="px-4 py-4">
            <div className="flex items-center text-grey-darker mb-4">
              <div className="w-[150px] bg-gray-300 h-6 rounded-md mb-1"></div>
            </div>
            <div className="flex">
              <div className="flex flex-row-reverse justify-end mr-2">
                <img
                  className="border-2 border-white rounded-full h-10 w-10 bg-gray-300"
                  alt=""
                />
                <img
                  className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                  alt=""
                />
                <img
                  className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                  alt=""
                />
                <img
                  className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                  alt=""
                />
                <img
                  className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                  alt=""
                />
                <img
                  className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                  alt=""
                />
              </div>
              <span className="flex items-center justify-center text-sm text-grey-darker font-semibold border-2 border-grey-light rounded-full h-10 w-10 bg-gray-300"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
