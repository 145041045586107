import { useEffect, useState } from "react";

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Models imports
import {
  IGraphDataGroup,
  IGraphDataUserProfile,
} from "../interfaces/IGraphData";

// Component imports
import { Header } from "../components/Header";
import { Loading } from "../components/Loading";
import { ErrorComponent } from "../components/ErrorComponent";

// Service imports
import GraphService from "../services/graph.service";
import { NotInAGroup } from "../components/NotInAGroup";
import { GroupData } from "../components/GroupData";

const graphService = new GraphService();

function GroupContent() {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | IGraphDataUserProfile>(
    null
  );
  const [groupData, setGroup] = useState<null | IGraphDataGroup>(null);

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      graphService
        .getMe()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });

      graphService
        .getGroup()
        .then((response) => setGroup(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [inProgress, graphData, groupData, instance]);

  return (
    <>
      {groupData && graphData ? (
        groupData?.value?.length > 0 ? (
          <>
            <Header user={graphData} />
            <GroupData graphData={groupData} />
          </>
        ) : (
          <NotInAGroup />
        )
      ) : (
        <Loading />
      )}
      {/* {graphData ? (
                <>
                    <Header user={graphData} />
                    <p>Grupos</p>
                </>
            ) : <Loading />} */}
    </>
  );
}

export function Group() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <GroupContent />
    </MsalAuthenticationTemplate>
  );
}
