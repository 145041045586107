import { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { NotAuthorized } from "../pages/NotAuthorized";
import { Loading } from "./Loading";

export const RouteGuard = ({ Component, ...props }) => {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const onLoad = async () => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount && currentAccount.idTokenClaims['roles']) {
            let intersection = props.roles
                .filter(role => currentAccount.idTokenClaims['roles'].includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    }

    useEffect(() => {
        onLoad().then(() => {
            setIsLoaded(true);
        }).catch(() => {
            setIsLoaded(false);
        });
        // eslint-disable-next-line
    }, [instance]);

    return (
        <>
            {!isLoaded && <Loading />}
            {isLoaded && <Route {...props} render={() => (
                isAuthorized ? (
                    <Component {...props} />
                ) : (
                    <NotAuthorized {...props} />
                ))} />}
        </>
    );
};