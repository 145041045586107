import { apiConfig } from "../authConfig";
import { IApiDataSendActivity } from "../interfaces/IApiData";

class APIService {
  public sendActivity = async ({ data, accessToken }: IApiDataSendActivity) => {
    return fetch(apiConfig.apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    })
      .then((response) => response.json())
      .catch((error) => error.json());
  };

  public getActivities = async (accessToken: string) => {
    return fetch(apiConfig.apiEndpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .catch((error) => error.json());
  };

  public deleteActivity = async (id: string, accessToken: string) => {
    return fetch(`${apiConfig.apiEndpoint}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .catch((error) => error.json());
  };

  public updateActivity = async (
    id: string,
    data: string,
    accessToken: string
  ) => {
    return fetch(`${apiConfig.apiEndpoint}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    })
      .then((response) => response.json())
      .catch((error) => error.json());
  };
}

export default APIService;
