import { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Group } from "../pages/Group";
import { NotAuthorized } from "../pages/NotAuthorized";
import { Loading } from "./Loading";

export const RouteHome = ({ Component, ...props }) => {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const onLoad = async () => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount && currentAccount.idTokenClaims['roles']) {
            let intersection = props.roles
                .filter(role => currentAccount.idTokenClaims['roles'].includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }

            if (currentAccount.idTokenClaims['roles'].includes('TaskAdmin')) {
                setIsAdmin(true);
            }
        }
    }

    useEffect(() => {
        onLoad().then(() => {
            setIsLoaded(true);
        }).catch(() => {
            setIsLoaded(false);
        });
        // eslint-disable-next-line
    }, [instance]);

    return (
        <>
            {!isLoaded ? (<Loading />) : (
                <Route
                    {...props}
                    render={() => (
                        isAuthorized ? (
                            <Component {...props} />
                        ) : (
                            isAdmin ? (
                                <Group {...props} />
                            ) : (
                                <NotAuthorized />
                            )
                        )
                    )}
                />
            )}
            {/* {isLoaded && isAuthorized ? (<Route {...props} component={Component} />) : (
                    isAdmin ? (<Route {...props} component={Group} />) : (<Route {...props} component={NotAuthorized} />)
                )} */}
            {/* {!isLoaded && <Loading />}
            {isLoaded && <Route {...props} render={() => (
                isAuthorized ? (
                    <Component {...props} />
                ) : (isAdmin ? (
                    <Group {...props} />
                ) : (
                    <NotAuthorized {...props} />
                )))} />} */}
        </>
    );
};