import Avatar from "react-avatar";

// Interfaces for the data that is returned from the Graph API
import { IGraphDataUserProfile } from "../interfaces/IGraphData";

export const UserData: React.FC<{ graphData: IGraphDataUserProfile }> = ({
  graphData,
}) => {
  return (
    <div className="font-sans leading-tight bg-grey-lighter m-2">
      <div className="max-w-lg mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
        <div
          className="bg-cover h-40"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1522093537031-3ee69e6b1746?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=a634781c01d2dd529412c2d1e2224ec0&auto=format&fit=crop&w=2098&q=80')`,
          }}
        ></div>
        <div className="border-b px-4 pb-6">
          <div className="text-center sm:text-left sm:flex mb-4">
            <div className="bg-cover m-2">
              <Avatar name={graphData.displayName} size={"80"} round={true} />
            </div>
            <div className="py-2">
              <h3 className="font-bold text-2xl mb-1">
                {graphData.displayName}
              </h3>
              <div className="inline-flex text-grey-dark sm:flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-grey mr-1"
                  fill="currentColor"
                  viewBox="0 0 64 64"
                >
                  <path
                    fill="#222"
                    d="M53.42 53.32H10.58a8.51 8.51 0 0 1-8.5-8.5V19.18a8.51 8.51 0 0 1 8.5-8.5h42.84a8.51 8.51 0 0 1 8.5 8.5v25.64a8.51 8.51 0 0 1-8.5 8.5ZM10.58 13.68a5.5 5.5 0 0 0-5.5 5.5v25.64a5.5 5.5 0 0 0 5.5 5.5h42.84a5.5 5.5 0 0 0 5.5-5.5V19.18a5.5 5.5 0 0 0-5.5-5.5Z"
                  />
                  <path
                    fill="#222"
                    d="M32 38.08a8.51 8.51 0 0 1-5.13-1.71L3.52 18.71a1.5 1.5 0 1 1 1.81-2.39L28.68 34a5.55 5.55 0 0 0 6.64 0l23.35-17.68a1.5 1.5 0 1 1 1.81 2.39L37.13 36.37A8.51 8.51 0 0 1 32 38.08Z"
                  />
                  <path
                    fill="#222"
                    d="M4.17 49.14a1.5 1.5 0 0 1-1-2.62l18.4-16.41a1.5 1.5 0 0 1 2 2.24L5.17 48.76a1.46 1.46 0 0 1-1 .38zm55.66 0a1.46 1.46 0 0 1-1-.38l-18.4-16.41a1.5 1.5 0 1 1 2-2.24l18.39 16.41a1.5 1.5 0 0 1-1 2.62z"
                  />
                </svg>
                {graphData.userPrincipalName}
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-4">
          <div className="flex items-center text-grey-darker mb-4">
            <span>
              <strong className="text-black">12</strong> Atividades enviadas
            </span>
          </div>
          {/* Atividades enviadas - pesquisa GET na API */}
          <div className="flex">
            <div className="flex flex-row-reverse justify-end mr-2">
              <img
                className="border-2 border-white rounded-full h-10 w-10 bg-gray-300"
                alt=""
              />
              <img
                className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                alt=""
              />
              <img
                className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                alt=""
              />
              <img
                className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                alt=""
              />
              <img
                className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                alt=""
              />
              <img
                className="border-2 border-white rounded-full h-10 w-10 -mr-2 bg-gray-300"
                alt=""
              />
            </div>
            <span className="flex items-center justify-center text-sm text-grey-darker font-semibold border-2 border-grey-light rounded-full h-10 w-10 bg-gray-300"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
