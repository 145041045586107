import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "3fb86b27-ba08-464c-bd4b-2e3ab7a42e73",
    authority:
      "https://login.microsoftonline.com/c551cc91-3f52-42c4-8068-374efa52eb5b",
    redirectUri: "https://www.unifacef.tech",
    // redirectUri: "http://localhost:3000",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphGetMyPhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
  graphMemberOfMeEndpoint:
    "https://graph.microsoft.com/v1.0/me/MemberOf/microsoft.graph.group?$count=true",
  graphGetMembersEndpoint:
    "https://graph.microsoft.com/v1.0/groups/{group-id}/members",
  graphGetUserEndpoint:
    "https://graph.microsoft.com/v1.0/users/{email-address}",
  graphGetNextPageEndpoint: "https://graph.microsoft.com/v1.0/{next-page-url}",
};

export const apiConfig = {
  apiEndpoint: "https://api.unifacef.tech/v1.0/activity",
};

export const protectedResources = {
  scopes: ["api://d6580bc1-7c1f-411f-b67d-1e3e15f5661b/access_as_user"],
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const CustomloginRequest = {
  scopes: [...protectedResources.scopes],
};

export const appRoles = {
  TaskUser: "TaskUser",
  TaskAdmin: "TaskAdmin",
};
