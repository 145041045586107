import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";

// Component imports
import { RouteHome } from "./components/RouteHome";
import { RouteGuard } from "./components/RouteGuard";

// Config imports
import { appRoles } from "./authConfig";

// Pages imports
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
import { Members } from "./pages/Members";
import { User } from "./pages/User";
import Error404 from "./components/Error404";

type AppProps = {
  pca: IPublicClientApplication;
};

export default function App({ pca }: AppProps) {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <Router>
      <MsalProvider instance={pca}>
        <AuthenticatedTemplate>
          <Pages />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
          />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </Router>
  );
}

function Pages() {
  return (
    <Switch>
      <RouteHome exact path="/" roles={[appRoles.TaskUser]} Component={Home} />
      <RouteGuard
        exact
        path="/profile"
        roles={[appRoles.TaskUser, appRoles.TaskAdmin]}
        Component={Profile}
      />
      <RouteGuard
        exact
        path="/Members/groupId/:id"
        roles={[appRoles.TaskAdmin]}
        Component={Members}
      />
      <RouteGuard
        exact
        path="/user/:email"
        roles={[appRoles.TaskAdmin]}
        Component={User}
      />
      <Route path="*" component={Error404} />
    </Switch>
  );
}
