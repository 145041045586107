// Interfaces for the data that is returned from the Graph API
import { IGraphDataGroup } from "../interfaces/IGraphData";

export const GroupData: React.FC<{ graphData: IGraphDataGroup }> = ({
  graphData,
}) => {
  return (
    <div className="pl-10 pr-10 grid grid-cols-1 gap-8 md:grid-cols-3">
      {graphData.value.map((group) => (
        <>
          <div className="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
              {group.displayName}
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">
              {group.description}
            </p>
            <a
              href={`/Members/groupId/${group.id}`}
              className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Visualizar membros
              <svg
                aria-hidden="true"
                className="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </>
      ))}
    </div>
  );
};
