import { useEffect, useState } from "react";

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Models imports
import { IGraphDataUserProfile } from "../interfaces/IGraphData";

// Component imports
import { Form } from "../components/Form";
import { Header } from "../components/Header";
import { Loading } from "../components/Loading";
import { ErrorComponent } from "../components/ErrorComponent";

// Service imports
import GraphService from "../services/graph.service";

const graphService = new GraphService();

function HomeContent() {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | IGraphDataUserProfile>(
    null
  );

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      graphService
        .getMe()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [inProgress, graphData, instance]);

  return (
    <>
      {graphData ? (
        <>
          <Header user={graphData} />
          <Form user={graphData} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export function Home() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <HomeContent />
    </MsalAuthenticationTemplate>
  );
}
