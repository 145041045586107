import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

// Config imports
import { loginRequest } from "../authConfig";

// Component imports
import { ErrorComponent } from "../components/ErrorComponent";
import { Header } from "../components/Header";
import { Loading } from "../components/Loading";
import { LoadingProfile } from "../components/LoadingProfile";
import { ProfileData } from "../components/ProfileData";

// Models imports
import { IGraphDataUserProfile } from "../interfaces/IGraphData";

// Service imports
import GraphService from "../services/graph.service";

const graphService = new GraphService();

const ProfileContent = () => {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | IGraphDataUserProfile>(
    null
  );

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      graphService
        .getMe()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [inProgress, graphData, instance]);

  return (
    <>
      {graphData ? (
        <>
          <Header user={graphData} />
          <ProfileData graphData={graphData} />
        </>
      ) : (
        <LoadingProfile />
      )}
    </>
  );
};

export function Profile() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ProfileContent />
    </MsalAuthenticationTemplate>
  );
}
