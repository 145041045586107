import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import Avatar from "react-avatar";
import { loginRequest } from "../authConfig";
import { IGraphDataMember } from "../interfaces/IGraphData";
import GraphService from "../services/graph.service";

const graphService = new GraphService();
export const MemberData: React.FC<{ graphData: IGraphDataMember }> = ({
  graphData,
}) => {
  const [totalMembers, setTotalMembers] = useState<IGraphDataMember>(graphData);
  const [foundMembers, setFoundMembers] =
    useState<IGraphDataMember>(totalMembers);
  const [search, setSearch] = useState("");

  const listMembersNextLink = totalMembers["@odata.nextLink"] as any;
  const { instance } = useMsal();

  const getNextLink = async () => {
    graphService
      .getNextLink({ nextLink: listMembersNextLink })
      .then((response) =>
        setTotalMembers({
          value: [...totalMembers.value, ...response.value],
          "@odata.nextLink": response["@odata.nextLink"],
        })
      )
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount() as AccountInfo,
          });
        }
      });
    filterMembers(search);
  };

  const filterMembers = (search: string) => {
    const keyword = search.toLowerCase();
    const filteredMembers = totalMembers.value.filter((member) => {
      return member.displayName.toLowerCase().includes(search.toLowerCase());
    });
    setFoundMembers({
      value: filteredMembers,
    });
    setSearch(keyword);
    console.log(filteredMembers);
  };

  return (
    <>
      <div className="m-4 relative">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-500"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          id="table-search-users"
          value={search}
          onChange={(e) => filterMembers(e.target.value)}
          className="block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Pesquisar usuários"
        />
      </div>
      <div className="m-4 overflow-x-auto relative shadow-md sm:rounded-lg">
        <div className="flex justify-between items-center py-4 bg-white"></div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">
                Nome
              </th>
              <th scope="col" className="py-3 px-6">
                Turma
              </th>
              <th scope="col" className="py-3 px-6">
                Atividades enviadas
              </th>
              <th scope="col" className="py-3 px-6">
                Ação
              </th>
            </tr>
          </thead>
          <tbody>
            {!totalMembers && (
              <div className="text-center">Não há mais usuários</div>
            )}
            {foundMembers && search.length > 0
              ? foundMembers.value.map((user) => (
                  <tr className="bg-white border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap"
                    >
                      <Avatar
                        name={user.displayName}
                        size={"45"}
                        round={true}
                      />
                      <div className="pl-3">
                        <div className="text-base font-semibold">
                          {user.displayName}
                        </div>
                        <div className="font-normal text-gray-500">
                          {user.userPrincipalName}
                        </div>
                      </div>
                    </th>
                    <td className="py-4 px-6">React Developer</td>
                    <td className="py-4 px-6">12</td>
                    <td className="py-4 px-6">
                      <a
                        href={`/user/${user.id}`}
                        type="button"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Ver atividades
                      </a>
                    </td>
                  </tr>
                ))
              : totalMembers.value.map((user) => (
                  <tr className="bg-white border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap"
                    >
                      <Avatar
                        name={user.displayName}
                        size={"45"}
                        round={true}
                      />
                      <div className="pl-3">
                        <div className="text-base font-semibold">
                          {user.displayName}
                        </div>
                        <div className="font-normal text-gray-500">
                          {user.userPrincipalName}
                        </div>
                      </div>
                    </th>
                    <td className="py-4 px-6">React Developer</td>
                    <td className="py-4 px-6">12</td>
                    <td className="py-4 px-6">
                      <a
                        href={`/user/${user.id}`}
                        type="button"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Ver atividades
                      </a>
                    </td>
                  </tr>
                ))}
            {listMembersNextLink && (
              <button
                onClick={getNextLink}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Ver mais
              </button>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
