import { useEffect, useState } from "react";

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Models imports
import {
  IGraphDataMember,
  IGraphDataUserProfile,
} from "../interfaces/IGraphData";

// Component imports
import { Header } from "../components/Header";
import { Loading } from "../components/Loading";
import { ErrorComponent } from "../components/ErrorComponent";

// Service imports
import GraphService from "../services/graph.service";
import { MemberData } from "../components/MemberData";
import { IMembers } from "../interfaces/IMembers";
import { useParams } from "react-router-dom";

const graphService = new GraphService();

function MemberContent() {
  const { id } = useParams<IMembers>();

  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | IGraphDataUserProfile>(
    null
  );
  const [membersData, setMembers] = useState<null | IGraphDataMember>(null);

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      graphService
        .getMe()
        .then((response) => setGraphData(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
      graphService
        .getMembers({ id })
        .then((response) => setMembers(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    }
  }, [inProgress, graphData, membersData, instance, id]);

  return (
    <>
      {membersData && graphData ? (
        <>
          <Header user={graphData} />
          <MemberData graphData={membersData} />
        </>
      ) : (
        <Loading />
      )}
      {/* {graphData ? (
                <>
                    <Header user={graphData} />
                    <p>Grupos</p>
                </>
            ) : <Loading />} */}
    </>
  );
}

export function Members() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <MemberContent />
    </MsalAuthenticationTemplate>
  );
}
