export default function Error404() {
  return (
    <div className="grid place-items-center h-screen">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="mt-4 mb-8 text-2xl font-semibold md:text-3xl">
            Página não encontrada
          </p>
          <p className="mb-8 text-lg font-light text-gray-500 dark:text-gray-400">
            O Servidor não encontrou o arquivo que você solicitou. A página pode
            ter sido movida ou excluída, ou você digitou um endereço ou nome de
            documento incorretos. Verifique o endereço. Se uma palavra estiver
            incorreta, corrija e tente de novo. Se isso não funcionar, tente a
            opção de busca para encontrar o que você está procurando.{" "}
          </p>
          <a
            rel="noopener noreferrer"
            href="/"
            className="px-8 py-3 font-semibold rounded text-white bg-blue-800 hover:bg-blue-900"
          >
            Voltar à página inicial
          </a>
        </div>
      </div>
    </div>
  );
}
